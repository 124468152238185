import React from "react"
import Layout from "../../components/layout/layout"
import InnerWrapper from "../../components/layout/innerWrapper"
import { Row, Col, Tabs, Tab } from "react-bootstrap"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import { Link } from "gatsby"
import AboutSidebar from "../../components/layout/about-sidebar"
import { TypeH2 } from "styled-icons/bootstrap"
import SEO from "../../components/seo"

function PrivacyContent() {
  return (
    <>
      <SEO
        title="Privacy Policy"
        description="At Walpole Outdoors, we are committed to respecting your right to privacy and protecting your information when visiting our website."
      />
      <h1 className="generalheader">Privacy Policy</h1>
      <p>
        At Walpole Outdoors, we value your business and appreciate your trust.
        Therefore, we take great care to safeguard information we gather from
        you so that you may feel secure when shopping. This information may
        include your name, mailing address, email address, telephone number and
        credit card number.
      </p>
      <p>
        All orders submitted to our site are encrypted with secure technology on
        your end and on ours. This is industry standard technology. Our security
        measures protect against the loss, misuse, or alteration of information
        we have collected at our site. It protects against a third party viewing
        your information. When you reach our checkout, a locked padlock icon
        appears on the lower portion of your screen to indicate you are in our
        secure shopping area.
      </p>
      <p>
        Pages requesting information will always have one of these symbols
        displayed. We keep any information entered during ordering confidential.
      </p>
      <p>
        We may email you from time to time with news about products and sale
        items that we feel may interest you.
      </p>
      <p>
        If you do not wish to order online, you may order by phone by calling
        866-753-8550 or by filling out the order form in our Selections catalog.
        <br />
      </p>

      <p>
        <strong>Information We May Collect</strong>
        <br />
      </p>
      <p>
        Walpole Outdoors may collect information that can identify you
        ("personal information"), such as your name, address, and email address
        when you (or other users) provide it to us when using our web site or in
        some other manner, from our business partners, and from other third
        parties.
      </p>
      <p>
        We may combine the personal information that we receive from different
        sources.
      </p>
      <p>
        We also may collect other types of information in the following ways
        when you visit our web site. <br />
        Our server logs automatically collect information, such as your IP
        address, your browser type and language, and the date and time of your
        visit, which helps us track users' movements around our site and
        understand trends.
      </p>
      <p>
        We may assign your computer one or more cookies which may collect
        information to facilitate access to our web site and to personalize your
        online experience.
      </p>
      <p>
        We may use standard Internet tools, such web beacons, which collect
        information that tracks your use of our web site and enables us to
        customize our services and advertisements.
      </p>
      <p>
        We do not knowingly collect personal information from children under the
        age of 13. Should we ever do so, we will comply with the Children's
        Online Privacy Protection Act.
      </p>
      <p>
        <strong>How We May Use Information We Collect </strong>
        <br />
        We may use information to:
        <br />
        <ul>
          <li>Fulfill your requests for products and services.</li>
          <li>Offer products and services that may be of interest to you.</li>
          <li>
            Customize the advertising and content that you see on our web site.
          </li>
          <li>Facilitate use of our web site.</li>
          <li>Manage your account and your preferences.</li>
          <li>
            Analyze use of and improve our web site, products and services.
          </li>
          <li>
            Identify and protect against fraudulent transactions and other
            misuses of our web site.
          </li>
        </ul>
      </p>

      <p>
        <strong>With Whom We May Share Information We Collect </strong>
        <br />
        We will not share your personal information with others except as
        indicated below, or except when we inform you in advance and give you
        the opportunity to opt out. We may share personal information with:
        <br />
        <ul>
          <li>
            Service providers, such as credit-card payment processors,
            performing services on our behalf.
          </li>
          <li>
            Other businesses with which we partner or which we carefully select
            to offer you products, services, and promotions through our web site
            or offline.{" "}
          </li>
          <li>
            Other third parties in limited circumstances, such as complying with
            legal requirements, preventing fraud, and protecting the safety of
            our users.
          </li>
          <li>
            We may share aggregated, non-personal information in any of the
            above situations and also with advertisers and others.
          </li>
        </ul>
      </p>

      <p>
        <strong>You may choose:</strong>
        <br />
        <ul>
          <li>
            Not to provide personal information, although that may result in
            your inability to obtain certain services or use certain features of
            our web site.
          </li>
          <li>
            To stop receiving promotional emails or catalogs from us by sending
            an email to the contact address below.
          </li>
          <li>
            To delete or decline cookies by changing your browser settings,
            although if you do so, some of the features or services of our web
            site may not function properly.
          </li>
          <li>
            To review and update your personal information by contacting us at
            the email address below or, if you have an online account on our web
            site, by logging into that account.{" "}
          </li>
          <li>
            Opt-out. If you do not wish to receive mailings from other companies
            or to have personal information shared with other companies,
            including other IAC businesses, please contact us by phone, email or
            mail.
          </li>
        </ul>
      </p>

      <p>
        <strong>Security</strong>
        <br />
        We take appropriate physical, electronic, and other security measures to
        help safeguard personal information from unauthorized access,
        alteration, or disclosure.
      </p>
      <p>
        <strong>Changes to our Privacy Policy</strong>
        <br />
        Walpole Outdoors may occasionally update our Privacy Policy to reflect
        changes in our practices and services. If we make material changes in
        the way we collect, use, or share your personal information, we will
        notify you by sending you an email to the email address you most
        recently provided to us and/or by prominently posting notice of the
        changes on our web site.
      </p>
      <p>
        <strong>How to Contact Us</strong>
        <br />
        If you have any questions about this summary, our Privacy Policy, or our
        information practices, please contact us by email or postal mail below.
      </p>
      <p>
        <strong>Walpole Outdoors Privacy Contact:</strong>
        <br />
        Toll Free: 866 753-8550
        {/* <br />
  <a href="../../../../contact.aspx?ContactUs=true">Contact Us</a> */}
        <br />
        Mail: 255 Patriot Place, Foxborough, MA 02035
      </p>
    </>
  )
}

function TermsContent() {
  return (
    <>
      <h2 className="generalheader">Terms and Conditions</h2>
      <h3>Customer Service</h3>
      <p>
        <strong>Online and Mail Order</strong>
        <br />
        Order by Phone: 866-491-4708
        <br />
        Order by fax: 508-668-6386
      </p>
      <p>
        We are always pleased to hear from our customers, whether by phone at
        866-491-4708 Monday - Friday 8 a.m. - 5 p.m., Saturday 9 a.m. - 5 p.m.,
        or visit the store nearest you. You can also Contact Us here.{" "}
      </p>
      <p>
        <strong>A warm welcome at Walpole stores.</strong>
      </p>
      <p>
        Customers are encouraged to visit our stores. There you may purchase
        items fully assembled or arrange for delivery and installation within
        the service area and call upon our trained, helpful staff to assist you
        in your choices.
      </p>
      <h3>Order, Payment & Shipping Questions</h3>

      <p>
        <strong>Online gift certificates</strong>
      </p>
      <p>
        At this time, Walpole Outdoors cannot accept gift certificates for all
        or partial payment of online purchases.
      </p>
      <p>
        <strong>Not all items are available online</strong>
      </p>
      <p>
        Our Web site offers a diverse range of items available from Walpole
        Outdoors. However, not everything is shown here, and not all of the
        items shown are available for purchase online at this time. For further
        assistance, stop by or call our store nearest you, or call 866-491-4708.
      </p>
      <p>
        <strong>Forms of Payment</strong>
      </p>
      <p>
        Walpole Outdoors accepts: VISA, MasterCard, American Express, and
        Discover
      </p>
      <p>
        <strong>Online help</strong>
      </p>
      <p>
        If you have any problems placing your order online, please call us
        immediately at 866-491-4708 and we'll be glad to help.
      </p>
      <p>
        <strong>Ordering</strong>
      </p>
      <p>
        Customers are always welcome at Walpole Outdoors stores. There you may
        purchase items fully assembled or arrange for delivery and installation.
      </p>
      <p>
        For our National Sales and Online customers many of our products are
        delivered to you in easy-to-assemble kits (indicated within the product
        description on our site as "Shipped kit."). Kits allow us to ship some
        items more economically. Full instructions are included. All that's
        required of you is a little time, a few basic tools, and you will have
        assembled quality products that are comfortable, sturdy, long-lasting
        and safe. Walpole Outdoors has been shipping product all over the
        country and even abroad for years. Our National Sales department will be
        happy to assist you with any assembly questions.
      </p>
      <p>
        <strong>Customer Satisfaction, Refunds and Exchanges</strong>
      </p>
      <p>
        We stand behind the quality of our products. If a product fails to meet
        your expectations, return it to the store where purchased within 30 days
        in its original packaging and we will refund, replace or exchange it for
        you. Online and National Sales customers must call 866-491-4708 for a
        Return Authorization Number. Shipping charges not refundable. A 15%
        restocking fee may apply.
      </p>

      <p>
        <strong>Prices and Sales Tax</strong>
      </p>
      <p>
        Pricing in this catalog supersede all previous catalogs. Availability,
        designs and pricing subject to change. We are required to collect sales
        tax in the following states: CT, MA, MD, ME, NJ, NY, PA, RI, VA and DC.
        This is because we have a store located in your state or have a state
        license for installation of our products.
      </p>
      <p>
        As you are probably well aware, Sales Tax is charged at different rates
        in different parts of the country (and often within the same state).
        Walpole calculates your actual tax. At the time of your order, so that
        you will pay only the correct, legal Sales Tax.
      </p>
      <p>
        <strong>Confirming placed orders</strong>
      </p>
      <p>
        Once you have placed your order, an order summary page will appear which
        you can print out for your records. We will send you an e-mail
        confirming the order.
      </p>
      <p>
        <strong>Canceling an order</strong>
      </p>
      <p>
        If you feel you have made a mistake concerning your order, please call
        us at 866-491-4708 as soon as possible.
      </p>
      <p>
        <strong>Domestic Shipping</strong>
      </p>
      <p>All our products can be shipped regular ground or Motor Freight.</p>
      <p>
        <strong>UPS or Ground Shipping-</strong> For ground delivery, charges
        will be calculated based on weight, dimensions and zip code at check
        out.
      </p>
      <p>
        <strong>Motor Freight-</strong>For Motor Freight, we can quote
        approximate costs to your delivery point. Deliveries can only be made to
        locations with a street address. All shipments must be signed for, so
        please include your phone number so the transportation company can
        notify you before delivery. The delivery driver is responsible only for
        delivery, not offloading. You may have to provide assistance for items
        difficult to unload from the truck.
      </p>
      <p>Upon Delivery:</p>
      <ul>
        <li>
          Check the Packing List- if there are discrepancies please call
          866-491-4708 as soon as possible.
        </li>
        <li>Check for damage or shortage.</li>
        <li>
          If your package or its contents are damaged - sign for it damaged.
          Call us immediately.
        </li>
      </ul>

      <p>
        <strong>Save on Shipping Charges–</strong> If you live near one of our
        store locations, you can save on shipping charges by ordering directly
        through the store and picking up the item yourself. We also offer
        assembly and delivery services from our stores, if that is more
        convenient for you.
      </p>
      <p>
        When ordering multiple products – which are calculated by individual
        assigned costs – we may be able to package them together and negotiate a
        better shipping charge for you. Please call National Sales at
        866-491-4708 or contact us to discuss shipping issues before placing
        your order.
      </p>
      <p>
        <strong>White Glove Delivery Service-</strong> Walpole offers a special
        service for any product that must ship motor freight. With White Glove
        Delivery, motor freight items are brought to your property, unloaded,
        placed in your yard and packaging is removed. White Glove Delivery
        Service varies from city to city. We can also provide a service to
        assemble products.&nbsp;Please call Customer Service at 866-491-4708 for
        further delivery details.
      </p>
      <p>
        <strong>International Shipping</strong>
      </p>
      <p>
        For foreign shipments call our National Sales Department at 866-491-4708
        and they will assist you.
      </p>
      <p>
        <strong>Shipment outside the contiguous US</strong>
      </p>
      <p>
        For shipment information to Hawaii, Puerto Rico, Alaska and foreign
        countries, please call customer service at 866-491-4708
      </p>
      <p>
        <strong>P.O. Boxes</strong>
      </p>
      <p>Shipments to post office boxes will be via US mail</p>
      <p>
        <strong>Custom Orders</strong>
      </p>
      <p>
        Many Walpole items can be made in sizes and colors other than those
        stated on this Web site or in our catalog for an additional cost. Please
        call and we'll try to satisfy your special request. Sorry, custom orders
        cannot be returned or refunded.
      </p>
    </>
  )
}

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <InnerWrapper>
        <div className="page-hero"></div>

        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/about">About</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/about/privacy-policy">Privacy Policy</Link>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Row className="generalcontent">
          <Col md={3}>
            <AboutSidebar></AboutSidebar>
          </Col>
          <Col md={9}>
            <Tabs defaultActiveKey="privacy" id="uncontrolled-tab-example">
              <Tab
                tabClassName="product_tab_title"
                eventKey="privacy"
                title={`Privacy Policy`}
              >
                <PrivacyContent />
              </Tab>
              <Tab
                tabClassName="project_tab_title"
                eventKey="terms"
                title={`Terms and Conditions`}
              >
                <TermsContent />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default PrivacyPolicyPage
